<template>
    <div>
      <div v-if="loading">Loading...</div>
      <div v-else-if="error" class="error">
        <p>{{ error }}</p>
        <button @click="retryFetch">Retry</button>
      </div>
      <div v-else>
        <h1>{{ post.title.rendered }}</h1>
        <div v-html="post.content.rendered"></div>
      </div>
    </div>
  </template>
  
  <script>
import wordpressService from '@/services/wordpressService';
  
  export default {
    data() {
      return {
        post: {},
        loading: true,
        error: null
      };
    },
    created() {
      const postId = this.$route.params.id;
      this.fetchPost(postId);
    },
    methods: {
      async fetchPost(postId) {
        this.loading = true;
        this.error = null;
        
        try {
          const response = await wordpressService.getPost(postId);
          this.post = response.data;
        } catch (err) {
          console.error('Error fetching post:', err);
          this.error = err.response?.data?.message || 
                      'Failed to load the post. Please try again later.';
        } finally {
          this.loading = false;
        }
      },
      retryFetch() {
        const postId = this.$route.params.id;
        this.fetchPost(postId);
      }
    }
  };
  </script>
  
  <style scoped>
  .error {
    color: red;
    padding: 1rem;
    margin: 1rem 0;
  }
  </style>