<template>
  <div class="container">
    <header>
      <nav class="desktop-nav">
        <!--<div class="nav-links">
          <router-link to="/" class="nav-item">Home</router-link> 
          <router-link to="/blog" class="nav-item">Blog</router-link>
          <div class="dropdown">
            <span class="nav-item">Projects</span>
            <div class="dropdown-content">
              <a v-for="project in projects" :key="project.id" :href="project.url" target="_blank">
                {{ project.title }}
              </a>
            </div>
          </div> 
        </div>--> 
      </nav>
      <div class="mobile-nav">
        <button @click="toggleMenu" class="hamburger-button" aria-label="Toggle menu">
          ☰
        </button>
        <HomeButton />
      </div>
    </header>

    <!-- Mobile menu -->
    <div v-if="isMenuOpen" class="mobile-menu open">
      <button @click="toggleMenu" class="close-button" aria-label="Close menu">×</button>
      <nav class="menu-nav">
        <router-link to="/" class="menu-item" @click="toggleMenu">Home</router-link>
        <router-link to="/blog" class="menu-item" @click="toggleMenu">Blog</router-link>
        <!-- <div class="menu-item projects-section">
          <p class="menu-item-title">Projects</p>
          <div class="projects-submenu">
            <a v-for="project in projects" :key="project.id" :href="project.url" target="_blank" class="project-link">
              {{ project.title }}
            </a>
          </div>
        </div> -->
      </nav>
    </div>

    <!-- Main content -->
    <main>
      <router-view></router-view>
    </main>

    <FooterSection />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import HomeButton from '@/components/HomeButton.vue';
import FooterSection from '@/components/FooterSection.vue';

const isMenuOpen = ref(false);

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.desktop-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-item {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.mobile-nav {
  display: none;
}

.hamburger-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0; /* Changed from right to left */
  width: 250px;
  height: 100%;
  background: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1); /* Changed shadow direction */
  z-index: 1001;
  overflow-y: auto;
  transform: translateX(-100%); /* Start off-screen */
  transition: transform 0.3s ease-in-out;
}

.mobile-menu.open {
  transform: translateX(0); /* Slide in from the left */
}

.close-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  float: right;
  padding: 5px;
}

.menu-nav {
  margin-top: 40px;
}

.menu-item {
  display: block;
  padding: 10px 0;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.projects-section {
  background-color: transparent;
}

.projects-section:hover {
  background-color: transparent;
}

.menu-item-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.projects-submenu {
  margin-left: 15px;
}

.project-link {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.project-link:hover {
  background-color: #f0f0f0;
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .mobile-menu.open {
    transform: translateX(0);
  }
}
</style>
