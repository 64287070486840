import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '@/components/HomePage.vue'
import BlogPage from '@/components/BlogPage.vue'
import BlogPostPage from '@/components/BlogPostPage.vue'




const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPage
  },
  {
    path: '/blog/:id',
    component: BlogPostPage, // Replace with your actual component for displaying a single post
  }
  // ... other routes ...
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router