<template>
  <aside class="blog-sidebar">
    <h3>Recent Posts</h3>
    <ul class="recent-posts">
      <li v-for="post in posts" :key="post.id" 
          :class="{ active: selectedPost && selectedPost.id === post.id }"
          @click="$emit('select-post', post)">
        {{ post.title.rendered }}
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'BlogPageRightColumn',
  props: {
    posts: {
      type: Array,
      required: true
    },
    selectedPost: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped>
.blog-sidebar {
  position: sticky;
  top: 40px; /* Match top padding of blog-layout */
  align-self: start;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 80px); /* viewport height minus top and bottom padding */
  overflow-y: auto;
  width: 300px;
}

.recent-posts {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recent-posts li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: color 0.2s;
}

.recent-posts li:hover {
  color: #42b883;
}

.recent-posts li.active {
  color: #42b883;
  font-weight: bold;
}

h3 {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .blog-sidebar {
    position: static;
    width: 100%;
    margin-top: 20px;
    max-height: none;
    overflow-y: visible;
  }
}
</style>
