<template>
  <div class="blog-container">
    <HomeButton />
    <div class="blog-layout">
      <!-- Main Content -->
      <div class="blog-main">
        <h1>My Blog</h1>
        <div v-if="selectedPost" class="blog-post">
          <h2>{{ selectedPost.title.rendered }}</h2>
          <p class="post-meta">Posted on {{ formatDate(selectedPost.date) }}</p>
          <div v-html="selectedPost.content.rendered"></div>
        </div>
        <div v-else-if="posts.length">
          <article v-for="post in posts" :key="post.id" class="blog-post">
            <h2>{{ post.title.rendered }}</h2>
            <p class="post-meta">Posted on {{ formatDate(post.date) }}</p>
            <div v-html="post.excerpt.rendered"></div>
            <button @click="selectPost(post)" class="read-more">Read More</button>
          </article>
        </div>
        <div v-else>
          <p>Currently there seems to be a problem with loading the blogposts.</p>
        </div>
      </div>
<!-- Right Column -->
<BlogPageRightColumn 
  :posts="posts"
  :selected-post="selectedPost"
  @select-post="selectPost"
/>
     
    </div>
  </div>
</template>

<script>
import HomeButton from '@/components/HomeButton.vue';
import BlogPageRightColumn from '@/components/BlogPageRightColumn.vue';
import wordpressService from '@/services/wordpressService';

export default {
  name: 'BlogPage',
  components: {
    HomeButton,
    BlogPageRightColumn
  },
  data() {
    return {
      posts: [],
      selectedPost: null
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await wordpressService.getPosts();
        this.posts = response.data;
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    selectPost(post) {
      this.selectedPost = post;
    }
  }
};
</script>

<style scoped>
/* Reset default margins */
:deep(*) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blog-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.blog-layout {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 40px;
  padding: 40px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.blog-main {
  min-width: 0;
  width: 100%;
}

.blog-post {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-meta {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 15px;
}

.read-more {
  background: #42b883;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s;
}

.read-more:hover {
  background: #3aa876;
}

h1 {
  margin-bottom: 30px;
  color: #2c3e50;
}

h2 {
  color: #2c3e50;
  margin-bottom: 15px;
}

h3 {
  color: #2c3e50;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .blog-layout {
    grid-template-columns: 1fr;
    padding: 20px;
  }
  
  .blog-sidebar {
    position: static;
    width: 100%;
    margin-top: 20px;
    max-height: none;
    overflow-y: visible;
  }
}
</style>